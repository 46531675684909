import React, { useContext } from 'react'
import { TextField } from './carbon-entitysync'
import { EntityContext } from '@parallelpublicworks/entitysync';
import DependsOn from './DependsOn';
import getYearDiffWithMonth from '../util/getYearDiff';

const PROGRAM_START_DATE = process.env.PROGRAM_START_DATE || '2025-06-22';

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhone(phone){
    // const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const re = /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/
    return re.test(String(phone));
}

function validateNumber(number){
    const re = /^[+-]?\d+(,|.\d+)?$/
    return re.test(String(number));
}

function validateminimum(number, minimum){
    const re = minimum
    return number >= re ? true : false ;
}

export default function TextQuestion({
    field, 
    question, 
    error, 
    setError, 
    setTouched, 
    touched, 
    required, 
    disabled = false, 
    type = 'text',
    }) {
    const number_type = question.field_number_type ? question.field_number_type : 'integer';
    const minimum = question.field_minimum ? number_type=== 'float' ? parseFloat(question.field_minimum) : parseInt(question.field_minimum) : null;
  
    const entityContext = useContext(EntityContext);

    const onChange = (value) => {
        value = value.imaginaryTarget ? value?.imaginaryTarget?.value : value?.target?.value || value || '';
        let has_value = touchField(value)
        // let is_valid = has_value && validate(value)
        let is_valid = has_value && validate(value)
        if(setError && (required || (!required && has_value))) {
            if(is_valid && type !== 'number') {
                setError(false)
            } else if(type === 'number') {

                const num_val = number_type === 'float' ? 
                validateminimum(parseFloat(value),minimum) 
                : validateminimum(parseInt(value), minimum);
                if((!num_val || num_val < 0 ) && required){
                    setError('Please review minimum criteria')
                }else{
                    setError(false)
                }
            } else {
                switch (type) {
                    case 'email':
                        setError('Please add a valid email address')
                        break
                    case 'phone':
                        setError('Please add a valid phone number')
                        break
                    default:

                        if(field === "field_birthdate" && !isBirthdateValid(value)) {
                          setError('You must be 18 years old to apply.');
                          break;
                        }

                        setError('Please complete this field')
                        break
                }
            }
        }
    }

    const validate = (value) => {
        switch (type) {
            case 'email':
                return validateEmail(value)
            case 'number':
                return !isNaN(value) && validateNumber(value)
            case 'phone':
                return validatePhone(value)
            default:
                if(field === "field_birthdate") return !!value && isBirthdateValid(value);

                return !!value
        }
    }

    const touchField = (value) => {
        let has_value = false
        switch (type) {
            case 'number':
                value = parseInt(`${value}`)
                has_value = typeof value === "number" && !isNaN(value)
                break;
            default:
                has_value = typeof value === 'string' && value.length > 0
                break;
        }
        if(!touched && has_value && setTouched) setTouched(true)
        return has_value
    }

    const isBirthdateValid = (birthdate) => {
      birthdate = new Date(birthdate);
      let age = getYearDiffWithMonth(birthdate, new Date(PROGRAM_START_DATE));
      return age >= 18;
    }

    let { field_depends_on, field_depends_on_option } = question.relationships;
    
    return (<div className={`task-question type-${type} number-type-${number_type}`}>
              <DependsOn
                dependsOn={field_depends_on}
                dependsOnOptions={field_depends_on_option}
                removeFieldError={() => setError(false)}
              >
                <TextField
                  id={question.drupal_id} 
                  light 
                  field={field} 
                  type={type} 
                  number_type={number_type} 
                  onChange={onChange} 
                  labelText={`${question.title} ${required ?  '' : '(optional)'}`} 
                  invalidText={!!error && touched ? error : ''} 
                  invalid={!!error && touched} 
                  disabled={disabled}
                  minimum={minimum} />
              </DependsOn>
            </div>)
}